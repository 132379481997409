<template>
	<div>
		<h3>{{ $t('monte.add_jument') }}</h3>
		<div>
            <form @submit.prevent>
	            <div class="form-group">
	                <div class="row">
	                	<div class="col-12">
			            	<div class="d-flex">
								<b-form-radio v-model="showTier" :value="false" class="mr-2">{{ $t('monte.contact') }}</b-form-radio>
								<b-form-radio v-model="showTier" :value="true" class="mr-2 mb-2">{{ $t('monte.tiers') }}</b-form-radio>
			            	</div>
			            </div>
	                	<div class="col">
			            	<SearchTiers
			            		v-if="showTier"
								:tiers_selected.sync="tiers"
								:preselected="tiers"
							/>
							<SearchContact
			            		v-else
								:contact_selected.sync="contact"
								:preselected="contact"
							/>
			                <!-- <e-select
			                	v-else
                            	v-model="contact"
	                            id="contact"
	                            track-by="contact_id"
	                            label="contact_label"
	                            :placeholder="$t('monte.selectionnez_contact')"
	                            :selectedLabel="$t('global.selected_label')"
	                            :options="contact_formatted"
	                            :loading="loadingContact"

	                            group-values="contacts"
	                        	group-label="group_label_contact"

	                            :searchable="true"
	                            :allow-empty="true"
	                            :show-labels="false"
			                >
			                	<template slot="option" slot-scope="{ option }">{{ option.contact_label }}</template>
			               		<template slot="singleLabel" slot-scope="{ option }">{{ option.contact_label }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
		               		</e-select> -->
		                </div>
		                <div class="col-auto">
		                	<button class="btn btn-secondary" @click="addTierOrContact"><font-awesome-icon :icon="['far', 'plus']" /></button>
		                </div>
		            </div>
	            </div>
	            <div class="form-group">
	                <label>{{ $t('monte.jument') }}</label>
	                <div class="row">
	                	<div class="col">
			                <e-select
			                    v-model="mare"
			                    id="mare"
			                    track-by="horse_id"
			                    label="horse_nom"
			                    :placeholder="$t('monte.selectionnez_jument')"
			                    :selectedLabel="$t('global.selected_label')"
			                    :options="mare_dispo"
			                    :searchable="true"
			                    :allow-empty="false"
			                    :show-labels="false"
	                            :loading="loadingMare"
			                />
			            </div>
			            <div class="col-auto">
		                	<button class="btn btn-secondary" @click="addMare"><font-awesome-icon :icon="['far', 'plus']" /></button>
		                </div>
		            </div>
	            </div>
	            <div v-if="mare">
	            	<label>{{ $t('mouvement.lieu_stationnement') }}</label>
	            	<template v-if="lieu_stationnement">	
						<h5 class="m-0">{{ lieu_stationnement.tiers_rs }}</h5>
                    	<i>{{ lieu_stationnement.tiers_address1}}, {{ lieu_stationnement.tiers_postalcode }} {{ lieu_stationnement.tiers_town | uppercase }} {{ lieu_stationnement.pays }}</i>
	            	</template>

					<button v-else class="btn btn-link" v-on:click.stop.prevent="changeLieuStationnement()">
						<span class="d-md-inline">{{ $t('lieu.definir_lieu') }}</span> <font-awesome-icon class="ml-2" :icon="['fal', 'long-arrow-right']" />
					</button>
					<hr>
	            </div>
	            <div v-if="!no_couple && no_contract" class="alert alert-warning">{{ $t('monte.no_contract') }}</div>
	            <div v-if="no_couple" class="alert alert-warning">{{ $t('monte.no_couple') }}</div>
	            <div v-if="close_saillie" class="alert alert-warning">{{ close_saille_message }}</div>
	            <div v-if="stallion_preselected" class="alert alert-warning">{{ $t('monte.stallion_preselected') }}</div>
	            <div v-if="mare" class="form-group">
	                <label>{{ $t('gynecologie.mare_status') }}</label>
	                <div class="row">
	                	<div class="col">
			                <e-select
	                            v-model="mare_status_selected"
	                            id="marestatus_id"
	                            track-by="marestatus_id"
	                            :placeholder="$t('gynecologie.selectionnez_etat')"
	                            :selectedLabel="$t('global.selected_label')"
	                            :options="mare_status"
	                            :searchable="false"
	                            :allow-empty="false"
	                            :show-labels="false"
	                        >
	                            <template slot="option" slot-scope="{ option }">{{ $t('gynecologie.'+option.marestatus_code) }}</template>
	                            <template slot="singleLabel" slot-scope="{ option }">{{ $t('gynecologie.'+option.marestatus_code) }}</template>
	                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
	                        </e-select>
	                    </div>
		            </div>
	            </div>
	            <div v-if="mare" class="form-group">
	            	<div class="row">
	                	<div class="col">
	            			<b-form-checkbox id="seasonmare_sanitary" v-model="sanitary"><label for="seasonmare_sanitary">{{ $t("gynecologie.sanitaire_complet") }}</label></b-form-checkbox>
	            		</div>
	            	</div>
	            </div>
	            <div class="form-group">
	            	<label>{{ $t('monte.stallion') }}</label>
	                <e-select
	                    v-model="stallion"
	                    id="stallion"
	                    track-by="horse_id"
	                    label="horse_nom"
	                    :placeholder="$t('monte.selectionnez_etalon')"
	                    :selectedLabel="$t('global.selected_label')"
	                    :options="stallion_dispo"
	                    :searchable="true"
	                    :allow-empty="false"
	                    :show-labels="false"
	                />
	            </div>
	            <div class="form-group">
	            	<label>{{ $t('monte.date') }}</label>
	                <e-datepicker v-model="date"></e-datepicker>
	            </div>
	            <div class="form-group">
	            	<label>{{ $t('monte.creneau') }}</label>
	                <e-select
	                    v-model="schedule"
	                    id="schedule"
	                    track-by="schedule_id"
	                    :placeholder="$t('monte.selectionnez_schedule')"
	                    :selectedLabel="$t('global.selected_label')"
	                    :options="schedule_dispo"
	                    :searchable="true"
	                    :allow-empty="false"
	                    :show-labels="false"
	                >
		                <template slot="option" slot-scope="{ option }">{{ option.schedule_start }} - {{ option.schedule_end }}</template>
	                	<template slot="singleLabel" slot-scope="{ option }">{{ option.schedule_start }} - {{ option.schedule_end }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
	               	</e-select>
	            </div>

	            <div class="form-group">
                    <label>{{ $t('formulaire.ajouter_commentaire') }}</label>
                    <textarea class="form-control" :placeholder="$t('formulaire.votre_commentaire')" v-model="commentaire"></textarea>
                </div>

	            <div class="text-center mt-5">
	                <b-button @click="checkForm" variant="primary" class="rounded-pill">
	                    <template v-if="schedule_info">
	                    	{{ $t('global.modifier') }} <font-awesome-icon :icon="['fal', 'edit']" class="ml-1"/>
	                	</template>
	                	<template v-else>
	                    	{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus-circle']" class="ml-1"/>
	                	</template>
	                </b-button>
	                <b-button @click="cancelAjout" class="rounded-pill ml-1">
						{{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']" class="ml-1"/> 
					</b-button>
	            </div>
	        </form>
        </div>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
	import Shutter from '@/mixins/Shutter'
	import MonteShutters from '@/mixins/shutters-manager/Monte'
	import Horse from '@/mixins/Horse'
	import PlanningMonte from '@/mixins/PlanningMonte'
	import Contact from '@/mixins/Contact'
	import Gynecologie from '@/mixins/Gynecologie'
	import Contract from '@/mixins/Contract'
	import { parsePhoneNumberFromString as parseMax, AsYouType } from 'libphonenumber-js/max'

	export default {
		name: 'AjoutMonteMain',
		props: ['horse_id', 'schedule_info', 'planning_id', 'schedule_id', 'date_preselected', 'season_id'],
		mixins: [Shutter, MonteShutters, Horse, PlanningMonte, Contact, Gynecologie, Contract],
		data () {
			return {
				mare: null,
				mare_dispo: [],
				stallion: null,
				stallion_dispo: [],
				contact: null,
				// contact_dispo: [],
				schedule: null,
				schedule_dispo: [],
				date: null,
				mare_status_selected: null,
				mare_status: [],
				season_mare: null,
				all_schedule: [],
				sanitary: false,
				loadingContact: false,
				loadingMare: false,
				// contact_formatted: [],
				commentaire: "",
				first_load: false,
				no_contract: false,
				no_couple: false,
				showTier: false,
				tiers: null,
				close_saillie: false,
				close_saille_message: '',
				lieu_stationnement: null,
				stallion_preselected: false,
				showPhone: false,
				contact_phone: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.mare_status = await this.loadMareStatus()

				if(!this.schedule_info) {
					this.loadMare(0)
					this.loadStallion()
					// this.loadContact(0)
					if(this.date_preselected) {
						this.date = this.date_preselected
					}
				}
				else {
					this.first_load = true
					this.date = this.schedule_info.schedulehorse_date
					// this.loadContact(this.schedule_info.schedulehorse_contact.contact_id)
					this.contact = this.schedule_info.schedulehorse_contact
					this.loadMare(this.schedule_info.schedulehorse_mare.horse_id)
					this.loadStallion(this.schedule_info.schedulehorse_stallion.horse_id)
					this.commentaire = this.schedule_info.schedulehorse_commentaire
					this.tiers = this.schedule_info.schedulehorse_tiers
					if(this.tiers.tiers_id) {
						this.showTier = true
					}
				}

				this.all_schedule = await this.getSchedules(this.planning_id)
			},

			async addMare() {
				let shutter = this.monteShutters['monte-horse-ajout']
				shutter.props.season_id = this.season_id

				shutter.onOk = async (horse_id) => {
					if(this.contact) {
						await this.setContactHorse(horse_id)
					}

					this.season_mare = await this.loadSeasonMareStallionBySeasonHorse(horse_id, this.season_id)
				    this.loadMare(horse_id)
				}

				this.shutterPanel().open(shutter)	
			}, 

			addTierOrContact() {
				if(this.showTier) {
					this.openAddTier()
				}
				else {
					this.addContact()
				}
			},

			addContact() {
				let shutter = this.monteShutters['monte-contact-ajout']

				if(this.mare) {
					shutter.props.horse_id = this.mare.horse_id
				}

				// shutter.onOk = (contact_id) => {
				//     this.loadContact(contact_id)
				// }

				this.shutterPanel().open(shutter)	
			},

			openAddTier() {
				let shutter = this.monteShutters['ajout-tiers']

				this.shutterPanel().open(shutter)
			},

			async setContactHorse(horse_id) {
				await this.addHorseContact(horse_id, this.contact.contact_id)
			},

			async loadMare(horse_id) {
				this.loadingMare = true
				this.mare = null
				// if(this.contact) {
				// 	this.mare_dispo = await this.getHorseFemelleByContact(this.contact.contact_id)
				// } else {
				this.mare_dispo = await this.getHorseFemelle()
				// }
				this.loadingMare = false

				if(horse_id !== 0) {
					this.mare = this.mare_dispo.find(mare => mare.horse_id == horse_id)
				}
			},

			async loadStallion() {
				this.stallion_dispo = await this.getHorsesStallion(false)
				const stallion_planning = await this.getStallionPlanning(this.planning_id)
				this.stallion_dispo = this.stallion_dispo.filter(stallion => stallion_planning.includes(stallion.horse_id))
				this.stallion = this.stallion_dispo.find(stallion => stallion.horse_id == this.horse_id)
			},

			// async loadContact(contact_id) {
			// 	this.loadingContact = true
			// 	const contact_dispo = await this.getAllContact()

			// 	if(this.mare) {
			// 		const contact_mare = await this.getContactByHorse(this.mare.horse_id, false)
			// 		const contact_mare_formatted = contact_mare.map(contact => contact.horsecontact_contact)
			// 		this.contact_dispo = contact_dispo.map(contact => {
			// 			contact.contact_mare = contact_mare_formatted.includes(contact.contact_id)
			// 			return contact
			// 		})
			// 	} else {
			// 		this.contact_dispo = contact_dispo.map(contact => {
			// 			contact.contact_mare = false
			// 			return contact
			// 		})
			// 	}

			// 	this.formatContact()

			// 	this.loadingContact = false

			// 	if(contact_id !== 0) {
			// 		const contacts = this.contact_formatted[0].contacts.concat(this.contact_formatted[1].contacts)
			// 		this.contact = contacts.find(contact => contact.contact_id == contact_id)
			// 	}
			// },

			async selectStatus() {
				this.stallion_preselected = false
				if(!this.mare) return
					
				this.season_mare = await this.loadSeasonMareStallionBySeasonHorse(this.mare.horse_id, this.season_id)
				this.season_mare = this.season_mare[0]
				if(this.season_mare) {
					this.mare_status_selected = this.mare_status.find(stat => stat.marestatus_id == this.season_mare.seasonmare_status)
					this.sanitary = this.season_mare.seasonmare_sanitary
					
					const seasonmarestallion = await this.getSeasonMareStallionDefaultBySeasonMare(this.season_mare.seasonmare_id)
					if(seasonmarestallion) {
						const new_stallion = this.stallion_dispo.find(stallion => stallion.horse_id == seasonmarestallion.seasonmarestallion_horse)

						if(this.stallion == null || new_stallion.horse_id != this.stallion.horse_id) {
							this.stallion = new_stallion
							this.stallion_preselected = true
						}
					}
				} else {
                    await this.addHorseSeasonMare(this.season_id, this.mare.horse_id, '')
                    this.selectStatus()
				}

				const previous_season = await this.getPreviousSeasonFromSeason(this.season_id)
				if(previous_season) {
					const previous = await this.loadSeasonMareStallionBySeasonHorse(this.mare.horse_id, previous_season.season_id)
					if(previous.length > 0 && previous[0].seasonmare_status) {
						this.mare_status_selected = this.mare_status.find(stat => stat.marestatus_id == previous[0].seasonmare_status)
					}
				}
			},

			async loadSchedules() {
				let schedule_taken = await this.getSchedulesByStallionMain(this.stallion.horse_id, this.date)
				schedule_taken = schedule_taken.map(schedule => schedule.schedulehorse_schedule)
				this.schedule_dispo = this.all_schedule.filter(schedule => {
					if(this.schedule_id && schedule.schedule_id == this.schedule_id && this.first_load) return true
					return !schedule_taken.includes(schedule.schedule_id)
				})
				this.first_load = false

				this.schedule = {}
				if(this.schedule_id) {
					this.schedule = this.schedule_dispo.find(schedule => schedule.schedule_id == this.schedule_id)
				}
			},

			cancelAjout() {
				this.cancel()
				this.shutterPanel().close('monte-horse-ajout')
				this.shutterPanel().close('monte-contact-ajout')
			},

			async checkForm() {
				const mare = this.mare ? this.mare.horse_id : null;
				const contact = (!this.showTier && this.contact) ? this.contact.contact_id : null;
				const tiers = (this.showTier && this.tiers) ? this.tiers.tiers_id : null;

				const schedulehorse_id = this.schedule_info ? this.schedule_info.schedulehorse_id : null

				if(mare && this.mare_status_selected) {
					await this.saveStatutSanitary(this.season_mare.seasonmare_id, this.mare_status_selected.marestatus_id, this.sanitary)
				}
				else if(mare) {
					await this.saveStatutSanitary(this.season_mare.seasonmare_id, null, this.sanitary)
				}

				if(mare) {
					const seasonmarestallion = await this.getSeasonMareStallionDefaultBySeasonMare(this.season_mare.seasonmare_id)
					if(!seasonmarestallion || seasonmarestallion.seasonmarestallion_horse != this.stallion.horse_id) {
						await this.editAllSeasonMareStallionDefault(this.season_mare.seasonmare_id, { seasonmarestallion_default: 0 })
						const seasonstallion = await this.getSeasonMareStallionBySeasonMareAndStallion(this.season_mare.seasonmare_id, this.stallion.horse_id)
						if(!seasonstallion) {
							await this.addSeasonMareStallion(this.season_mare.seasonmare_id, this.stallion.horse_id, null, 1)
						} else {
							await this.editSeasonMareStallion(seasonstallion.seasonmarestallion_id, { seasonmarestallion_default: 1 })
						}
					}
				}

				const res = await this.saveScheduleHorse("main", this.stallion.horse_id, this.schedule.schedule_id, mare, contact, this.date, schedulehorse_id, this.commentaire, tiers)
				if(res) {
					this.ok()
					this.shutterPanel().close('monte-main-ajout')
					this.shutterPanel().close('monte-horse-ajout')
					this.shutterPanel().close('monte-contact-ajout')
				}
			},

			formatPhone(number) {
				return new AsYouType(Vue.i18n.locale()).input(number)
			},

			// formatContact() {
			// 	let contact_mare = []
			// 	let contact_autre = []

			// 	this.contact_dispo.forEach(contact => {
			// 		let contact_label = contact.contact_firstname + ' ' + contact.contact_lastname
			// 		if(contact.phones.length > 0) contact_label += ' (' + this.formatPhone(contact.phones[0].phone_combine) + ')'

			// 		if(contact.contact_mare) {
			// 			contact_mare.push({contact_id: contact.contact_id, contact_label: contact_label})
			// 		} else {
			// 			contact_autre.push({contact_id: contact.contact_id, contact_label: contact_label})
			// 		}
			// 	})

			// 	this.contact_formatted = [
			// 		{group_label_contact: this.getTrad('monte.contact_mare'), contacts: contact_mare},
			// 		{group_label_contact: this.getTrad('monte.contact_autre'), contacts: contact_autre}
			// 	]
			// },

			async loadContractInfo() {
				this.no_contract = false
				if(!this.mare || !this.mare.horse_id) return false

				const contrats = await this.loadContracts(this.mare.horse_id)

				const avenant = contrats.filter(avenant => {
					if(avenant.contract.length === 0 || avenant.contract.config.length === 0) return false
					if(!avenant.contract.config.horse) return false
					return avenant.contract.config.horse.horse_id == this.stallion.horse_id
					&& avenant.contract.config.season.season_id == this.season_id
					&& avenant.avenant_id == avenant.contract.avenant.avenant_id
				})

				if(avenant.length == 0) {
					this.no_contract = true
				}
			},

			async checkSeasonMareStallion() {
				this.no_couple = false
				if(!this.season_mare && this.mare && this.mare.horse_id && this.season_id) {
					this.season_mare = await this.loadSeasonMareStallionBySeasonHorse(this.mare.horse_id, this.season_id)
					this.season_mare = this.season_mare[0]
				}
 
				if(this.season_mare) {
					this.no_couple = true
					const seasonstallion = await this.getSeasonMareStallionBySeasonMareAndStallion(this.season_mare.seasonmare_id, this.stallion.horse_id)
					if(seasonstallion) {
						this.no_couple = false
					}
				}
			},

			async checkSaillie() {
				// affiche un message s'il y a une saillie entre 2 jours avant et après le créneau
				this.close_saillie = false
				if(this.mare && this.mare.horse_id) {
					let date_prev = new Date(this.date);
					date_prev.setDate(this.date.getDate() - 2);

					let date_next = new Date(this.date);
					date_next.setDate(this.date.getDate() + 2);

					const saillie = await this.getSaillieByMareBetweenDate(this.mare.horse_id, date_prev, date_next)
					if(saillie.length > 0) {
						this.close_saillie = true
						this.close_saille_message = this.getTrad('monte.close_saillie', {date: saillie[0].actes_date.toLocaleDateString()})
					}
				}
			},

			async loadLieuStationnement() {
				this.lieu_stationnement = await this.getHorseLieuStationnement(this.mare.horse_id)
			},

			changeLieuStationnement() {
				let shutter = this.monteShutters['mvt-lieu-stationnement']
				shutter.props.horse_id = this.mare.horse_id

				shutter.onOk = async (tiers) => {
            		await this.assignLieuStationnement(this.mare.horse_id, tiers.tiers_id)
            		this.loadLieuStationnement()
				}

				this.shutterPanel().open(shutter)	
			},

			collapsePhoneSearch() {
				if(this.showPhone) {
					this.showPhone = false
				}
				else {
					this.showPhone = true
				}
			}
		},

		watch: {
			mare (val) {
				// if(!this.contact && !this.schedule_info) {
				// 	this.loadContact(0)
				// }
				this.selectStatus()
				this.loadContractInfo()
				this.checkSeasonMareStallion()
				this.checkSaillie()
				this.loadLieuStationnement()
			},

			// contact (val) {
			// 	if(!this.mare && !this.schedule_info) {
			// 		this.loadMare(0)
			// 	}
			// },

			stallion (val) {
				if(val) { 
					this.loadSchedules()
					this.loadContractInfo()
					this.checkSeasonMareStallion()
				}
			},

			date() {
				if(this.stallion) {
					this.loadSchedules()
				}
				this.checkSaillie()
			}
		},

		components: {
			SearchTiers : () => import('@/components/Contract/SearchTiers'),
			SearchContact : () => import('@/components/Contract/SearchContact')
		}
	}

</script>